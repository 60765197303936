import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';

import { loadAppState } from './mainService'; // Import utility for app state
import i18n from '../plugins/i18n';
import config from '../syntopix.config';
import localStorageService from './localStorageService';
const isSyntopix = true; //process.env.VUE_APP_CONTEXT === 'syntopix';

const getRouterAndApp = async () => {
  // Determine context at runtime

  // Use dynamic imports for the router and App
  const router = isSyntopix
    ? (await import('../router/syntopixRouter')).default
    : (await import('../router/tensorRouter')).default;

  const App = isSyntopix
    ? (await import('../syntopix/App.vue')).default
    : (await import('../App.vue')).default;

  return { App, router };
};

function setPk(pk) {
  localStorageService.setItem('pk', pk);
  console.log(`PK set (Browser): ${pk}`);
}

function createKeysMan(pk) {
  const namespace = `19:syntopix`;
  return {
    topicStreamKey: `${namespace}:topics:stream`,
    pkTopicsKey: `${namespace}:pks:${pk}:topics`,
    topicContentKey: `${namespace}:topics:content`,
    topicSpaceKey: `${namespace}:topics:spaces`,
    pkSpacesKey: `${namespace}:pks:${pk}:spaces`,
    spaceOrderKey: `${namespace}:pks:${pk}:spaces:order`,
    topicOrderKey: `${namespace}:spaces::topics:order`,
    spaceKey: `${namespace}:spaces:${pk}`,
    pk,
  };
}
async function initializeSocketAndApp() {
  const host = config.VUE_APP_HOST;
  // const host = 'http://localhost:3335';
  console.warn('Connecting to Socket.IO server at:', host);

  // this is where open the socket and trigger the handshake event
  const socket = io(host, {
    auth: { userID: localStorage.getItem('pk') }, // get new or existing PK
  });

  async function launchApp() {
    (async () => {
      const { router, App } = await getRouterAndApp();
      console.log('\nLoaded router:', router); // Log the router
      console.log('Loaded App:', isSyntopix ? 'Syntopix' : 'Tensor', '\n'); // Log the App context

      Vue.use(VueSocketIOExt, socket);

      Vue.config.productionTip = false;
      new Vue({
        vuetify,
        router,
        i18n,
        render: (h) => h(App),
        mounted() {
          loadAppState();
        },
      }).$mount('#app');
    })();
  }
  // Wait for the handshake event
  socket.on('handshake', ({ pk, socketID }) => {
    console.log('Received handshake:', { pk, socketID });

    // Save the PK and attach globals
    setPk(pk);
    const keysMan = createKeysMan(pk); // Create `keysMan` dynamically

    Vue.prototype.$pk = pk;
    Vue.prototype.$socket = socket;
    Vue.prototype.$socketID = socketID;

    console.log('Starting Vue app with PK and Socket ID:', { pk, socketID });
    console.log('Sending keysMan to Node:', keysMan);
    socket.emit('set_keysMan', keysMan); // Send `keysMan` to Node

    launchApp();
  });

  socket.on('connect_error', (err) => {
    console.error('Socket connection error:', err);
  });

  socket.on('disconnect', () => {
    console.warn('Socket disconnected.');
  });

  socket.on('reconnect', (attempt) => {
    console.log(`Socket reconnected after ${attempt} attempts.`);
  });
}

export default initializeSocketAndApp;
