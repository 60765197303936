const ENV = process.env.NODE_ENV || 'development';

const config = {
  development: {
    VUE_APP_HOST: 'http://localhost:3335',
  },
  production: {
    VUE_APP_HOST: 'https://your-production-server.com',
  },
  staging: {
    VUE_APP_HOST: 'http://staging-server.com',
  },
};

export default config[ENV];
