// Error Handlers
window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global error handler:', error);
  console.error('Message:', message);
  console.error('Source:', source);
  console.error('Line number:', lineno);
  console.error('Column number:', colno);
};

import Vue from 'vue';
import './registerServiceWorker';
import initializeSocketAndApp from './services/socketService';
import { setupGlobalVariables } from './services/mainService'; 

// Run Initial Setup
setupGlobalVariables(Vue);

// Initialize the App with Socket and PK Handling
initializeSocketAndApp();

